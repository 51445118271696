
section {
  position: absolute;
  width: 100%;
  height: 100vh;
  overflow: hidden;
}

section video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 0;
}

section .gsmr-logo {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  font-size: 24rem;
  font-weight: bold;
  align-items: center;
  flex-direction: column;
  color: #fff;
  background: #000;
  mix-blend-mode: multiply;
  z-index: 1;
  background: rgba(0,0,0,0.95);
}